// Here you can add other styles

.danger-alert {
  background: #e9162b;
  border-radius: 5px;
  color: white;
}
.success-alert {
  background: #2a87d0;
  border-radius: 5px;
  color: white;
}
.PhoneInput {
  height: 55%;
}
.PhoneInputInput {
  border: none;
}
